export default [
    {type:"Action"},
    {type:"Adventure"},
    {type:"Animation"},
    {type:"Classic"},
    {type:"Comedy"},
    {type:"Drama"},
    {type:"Family"},
    {type:"Fatasy"},
    {type:"Horror"},
    {type:"Music"},
    {type:"Romance"},
    {type:"Sci-Fi & Fantasy"},
    {type:"Scifi"},
    {type:"Suspense"},
    {type:"Thriller"},
  ]