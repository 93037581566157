import sizes from "./sizes";

const styles = (theme) => ({
  "@global": {
    ".react-calendar__navigation": {
      padding: "0",
    },
    ".react-calendar__navigation__arrow": {
      display: "none",
    },
    ".react-calendar__navigation__label": {
      fontFamily: "Montserrat-Bold!important",
      margin: "24px 16px 16px",
      color: "hsla(0,0%,100%,.2)",
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: "500",
      backgroundColor: "transparent",
      border: "none",
    },
    ".react-calendar__month-view__weekdays__weekday": {
      flex: "1 1 40px",
      padding: "10px 0",
      height: "60px",
      position: "relative",
      textAlign: "center",
      fontFamily: "Montserrat-Bold!important",
      fontSize: ".9375rem",
      fontWeight: "500",
      color: "hsla(0,0%,100%,.4)",
      background: "none",
      border: "none",
      margin: "0",
      padding: "0",
      zIndex: "2",
      justifyContent: "center",
      alignItems: "center",
      display: "grid",
      "& abbr": {
        textDecoration: "none",
      },
    },
    ".react-calendar__month-view__days__day--neighboringMonth": {
      color: "hsla(0,0%,100%,.4)!important",
    },
    ".react-calendar__tile--rangeStart": {
      color: "#3d2d5e!important",
      "& abbr": {
      color: "#3d2d5e!important",
      background: "#fff!important",

      boxShadow: "0 8px 12px -4px rgb(0 0 0 / 50%)!important",
      }
    },
    ".react-calendar__month-view__days__day": {
      flex: "1 1 40px",
      height: "100%",
      position: "relative",
      textAlign: "center",
      fontFamily: "Montserrat-Bold!important",
      fontSize: ".9375rem",
      fontWeight: "500",
      color: "#fff",
      background: "none",
      border: "none",
      margin: "0",
      padding: "0",
      zIndex: "2",
      "& abbr": {
        display: "inline-block",
        width: "40px",
        lineHeight: "40px",
        borderRadius: "50%",
        cursor: "pointer",
      },
    },
  },
  root: {
    display: "flex",
    fontFamily: "Montserrat-Bold!important",
  },
  typeO: {
    fontFamily: "Montserrat-Bold!important",
    fontSize: "32px",
    letterSpacing: ".2px",
    lineHeight: "42px",
    fontWeight: "500",
    padding: "4px 0",
    [sizes.down("md")]: {
      fontSize: "25px",
      lineHeight: "30px",
    }
  },
  buttonClick: {
    display: "inline-block",
    "&:hover span:after": {
      transition: "background-position .6s ease-in-out",
      backgroundPosition: "-400px 0",
      backgroundImage:
        "linear-gradient(90deg,#794fc6,#794fc6 40%,#ffb985 60%,#794fc6 70%)",
    },
  },
  movieType: {
    color: "#fff",
    cursor: "pointer",
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: "14px",
      left: "0",
      right: "14px",
      position: "relative",
      height: "14px",
      zIndex: "-1",
      background: "#794fc6 0 0 repeat-y",
      backgroundSize: "900px 100%",
      backgroundAttachment: "fixed",
      transition: "background-position .6s ease-in-out",
    },
  },
  drawer: {
    width: "400px",
    flexShrink: 0,
    [sizes.down("md")]: {
      width: "100vw",
      height: "300px",
    },
    [sizes.down("sm")]: {
      width: "100vw",
      height: "250px",
    },
  },
  drawerPaper: {
    width: "400px",
    backgroundColor: "#1b1625",
    [sizes.down("md")]: {
      width: "100vw",
      height: "300px",
    },
    [sizes.down("sm")]: {
      width: "100vw",
      height: "250px",
    },
  },
  content: {
    flexGrow: 1,
    height: "calc(100vh - 64px)",
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [sizes.down("md")]: {
      marginLeft: "-100vw",
      marginTop: "300px",
      marginBottom: "-300px",
      height: "62vh",
    },
    [sizes.down("sm")]: {
      marginLeft: "-100vw",
      marginTop: "250px",
      marginBottom: "-300px",
      height: "62vh",
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawHeaderType: {},
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "top",
    height: "100%",
    color: "#3d2d5e",
    padding: "8px 16px 32px",
  },
  icon: {
    color: "#fff",
  },
  refresh: {
    position: "absolute",
    right: "0",
  },
  locContainer: {
    position: "absolute",
    bottom: "0",
    width: "100%",
  },
  loc: {
    background: "none",
    margin: "0",
    display: "-webkit-box",
    display: "-ms-flexbox",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    color: "#fff",
    fontWeight: "500",
    border: "none",
    borderTop: "1px solid #3d2d5e",
    cursor: "pointer",
    fontSize: ".9375rem",
    height: "80px",
    padding: "0 24px",
    [sizes.down("md")]: {
      height: "50px"
    }
  },
  rootImg: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,

    [sizes.down("md")]: {
      maxWidth: "100vw",
    },
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "#fff",
    fontFamily: "Montserrat-Bold!important",
    fontSize: ".9375rem",
    fontWeight: "400",
    padding: "0 24px",
    lineHeight: "1.4",
    "&:after": {
      content: '""',
      display: "block",
      margin: "8px auto",
      width: "10px",
      height: "1px",
      background: "#ffb985",
    },
  },
  subtitle: {
    fontFamily: "Montserrat-Bold!important",
    paddingTop: "9px",
    fontSize: ".9375rem",
    fontWeight: "300",
    color: "hsla(0,0%,100%,.37647)",
  },
  titleBar: {
    background: "none",
    zIndex: "100",
    paddingTop: "0px",
    textAlign: "center",
    opacity: "0",
    transition: "all 0.6s ease-in-out",
    backgroundImage: "linear-gradient(to bottom, #000, transparent)",
  },
  poster: {
    width: "300px!important",
    height: "101vh!important",
    padding: "0!important",
    "&:hover $titleBar": {
      transition: "all 0.6s ease-in-out",
      paddingTop: "24px",
      opacity: "1",
    },
    "&:hover $backGround": {
      transition: "opacity 0.6s ease-in-out",
      opacity: ".4",
    },
    "&:hover $miniPosterContainer": {
      transition: "bottom 0.6s ease-in-out",
      bottom: "5%",
    },
    "&:hover $play": {
      transition: "opacity 0.6s ease-in-out",
      opacity: "1",
    },
    [sizes.down("md")]: {
      width: "144px!important",
      height: "63vh!important",
    },
    [sizes.down("sm")]: {
      width: "119px!important",
      height: "70vh!important",
    },
  },
  backGround: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000",
    opacity: ".6",
    position: "absolute",
    zIndex: "20",
    transition: "opacity 0.6s ease-in-out",
  },
  miniPosterContainer: {
    position: "absolute",
    right: "0%",
    bottom: "0%",
    padding: "50px 25px",
    zIndex: "100",
    transition: "bottom 0.6s ease-in-out",
  },
  miniPoster: {
    width: "100%",
  },
  play: {
    position: "absolute",
    transition: "opacity 0.6s ease-in-out",
    opacity: "0",
    top: "auto",
    bottom: "17px",
    left: "39%",
    right: "0",
    margin: "0",
    width: "64px",
    height: "64px",
    background: "#794fc6",
    color: "#794fc6",
    borderRadius: "3px",
    "& svg": {
      height: "24px",
      width: "24px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    height: "80%",
    alignItems: "flex-end",
    width: "65%",
    margin: "auto",
    marginTop: "4%",
    boxShadow: "none",
    backgroundColor: "none",
    [sizes.down("md")]: {
      margin: "4% 10px",
      width: "48.5%",
      height: "42%",
    },
  },
  frame: {
    maxWidth: "1920px",
    width: "100%",
    maxHeight: "80vh",
    height: "100%",
  },
  [sizes.down("md")]: {
    maxWidth: "720px",
  },
  filterTop: {
    color: "#fff",
    fontSize: "2rem",
    padding: "80px 24px",
    cursor: "pointer",
    "&:after": {
      background:
        "linear-gradient(300deg,rgba(121,79,198,.5),rgba(198,125,186,.6) 50%,rgba(255,185,133,.7))",
      display: "block",
      content: "''",
      width: "100%",
      left: "0",
      height: "250px",
      position: "absolute",
      top: "0",
      zIndex: "-1",
    },
  },
  gridCat: {
    padding: "50px 25px 50px 10px",
  },
  catBtn: {
    fontFamily: "Montserrat-Bold!important",
    height: " 40px",
    padding: "0 15px",
    color: "#3d2d5e",
    background: "transparent",
    borderRadius: "2px",
    border: "1px solid #3d2d5e",
    fontSize: ".9375rem",
    fontWeight: "500",
    letterSpacing: "0",
    cursor: "pointer",
    boxShadow: "0 0 12px 0 transparent",
    transition: "color .15s,background-color .15s,-webkit-box-shadow .15s",
    transition: "color .15s,background-color .15s,box-shadow .15s",
    transition:
      "color .15s,background-color .15s,box-shadow .15s,-webkit-box-shadow .15s",
    "&:hover": {
      color: "#fff",
    },
  },
});

export default styles;
