import React, { useState } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import RefreshIcon from "@material-ui/icons/Refresh";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import styles from "../styles/page";
import cats from "../js/cats";
import Calendar from "react-calendar";

function Page(props) {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [openDraw, setOpenDraw] = useState(true);
  const [openType, setOpenType] = useState(false);
  const [openTypeDate, setOpenTypeDate] = useState(false);
  const [cat, setCat] = useState(null);
  const [mod, setMod] = useState(null);
  function handleOpen(val) {
    setMod(val);
    setOpen(true);
  }
  let mArray;
  if (cat === null) {
    mArray = props.movies[0].concat(
      props.movies[1],
      props.movies[2],
      props.movies[3]
    );
  } else {
    var filt = props.movies[0].concat(
      props.movies[1],
      props.movies[2],
      props.movies[3]
    );

    var newFilt = filt.filter(function (el) {
      return (
        el.genres[0] == cat[0] ||
        el.genres[0] == cat[1] ||
        el.genres[0] == cat[2]
      );
    });
    mArray = newFilt;
  }
  const handleRefresh = () => {
    setCat(null);
  };
  const handleDrawerTypeOpen = () => {
    setOpenType(true);
  };
  const handleDrawerTypeClose = () => {
    setOpenType(false);
  };
  const handleDrawerTypeDateOpen = () => {
    setOpenTypeDate(true);
  };
  const handleDrawerTypeDateClose = () => {
    setOpenTypeDate(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function handleCats(val) {
    if (cat === null) {
      var a = [val.toLowerCase()];
    } else {
      a = [...cat, val.toLowerCase()];
    }
    setCat(a);
  }
  let dateText;
  if (props.currentDate === props.date){
    dateText =  "today"
  }
  else{
    var sl = props.date.slice(4)
    var d = sl.slice(2,4)
    var m = sl.slice(0,2)
    var r = m.replace('02', 'Feb')
    dateText = `${d} ${r}`

  }
  let body;
  if (mod === null) {
    body = <div></div>;
  } else {
    const { classes } = props;
    body = (
      <div className={classes.modal}>
        <iframe
          className={classes.frame}
          allow="autoplay"
          id="ytplayer"
          type="text/html"
          src={`https://www.youtube.com/embed/${mod}?autoplay=1`}
          frameborder="0"
        ></iframe>
      </div>
    );
  }
  const catBlock = cats.map((c) => {
    return (
      <Grid item lg={4}>
        <button
          onClick={(e) => handleCats(c.type, e)}
          className={classes.catBtn}
        >
          {c.type}
        </button>
      </Grid>
    );
  });
  const movieFilter = mArray
    .filter((value) => Object.keys(value.assets).length !== 0)
    .map((p, index) => {
      const st = p.release.split("T");
      const sd = st[0].split("-");
      const newD = sd[2] + "/" + sd[1] + "/" + sd[0];
      return (
        <ImageListItem key={p.id} className={classes.poster}>
          <div className={classes.backGround}></div>
          <img
            src={
              p.assets.backdrop
                ? `https://dx35vtwkllhj9.cloudfront.net/${p.assets.backdrop}`
                : `https://dx35vtwkllhj9.cloudfront.net/${p.assets.onesheet}`
            }
            alt={p.title}
          />
          <ImageListItemBar
            title={p.title}
            position="top"
            subtitle={newD}
            classes={{
              root: classes.titleBar,
              title: classes.title,
              subtitle: classes.subtitle,
            }}
          />
          <div className={classes.miniPosterContainer}>
            <img
              className={classes.miniPoster}
              src={`https://dx35vtwkllhj9.cloudfront.net/${p.assets.onesheet}`}
            />
            <div
              className={classes.play}
              onClick={(e) => handleOpen(p.assets.youtube_id, e)}
            >
              <svg
                version="1.1"
                x="0px"
                y="0px"
                preserveAspectRatio="none"
                viewBox="0 0 16 16"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <path
                    d="M2,0.82 L2,15.18 C2,15.7 2.246,16 2.604,16 C2.751,16 2.917,15.949 3.092,15.841 L15.55,8.672 C15.85,8.488 16,8.244 16,8 C16,7.756 15.85,7.513 15.55,7.328 L3.092,0.159 C2.916,0.051 2.751,0 2.604,0 C2.246,0 2,0.301 2,0.82 Z"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </ImageListItem>
      );
    });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="persistent"
        anchor="left"
        open={openDraw}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton className={classes.icon}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className={classes.container}>
          <Typography variant="h4" gutterBottom className={classes.typeO}>
            Show trailers
            <br /> for
            <div className={classes.buttonClick}>
              <span
                className={classes.movieType}
                onClick={handleDrawerTypeOpen}
              >
                {cat ? `${cat}` : "All Movies"}
              </span>
            </div>
            <br /> Playing{" "}
            <div className={classes.buttonClick}>
              {" "}
              <span
                className={classes.movieType}
                onClick={handleDrawerTypeDateOpen}
              >
                {dateText}
              </span>
            </div>
          </Typography>
        </div>
        <div className={classes.locContainer}>
          <Divider />
          <Typography variant="body1" gutterBottom className={classes.loc}>
            Location
          </Typography>
        </div>
        <div className={`${classes.container} ${classes.catDraw}`}>
          <Drawer
            variant="persistent"
            anchor="left"
            open={openType}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeaderType}>
              <IconButton
                className={classes.icon}
                onClick={handleDrawerTypeClose}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                className={`${classes.icon} ${classes.refresh}`}
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </IconButton>
              <div>
                <div className={`${classes.filterTop} ${classes.container}`}>
                  Movies
                </div>
              </div>

              <div>
                <Grid className={classes.gridCat} container spacing={3}>
                  {catBlock}
                </Grid>
              </div>
            </div>
          </Drawer>
        </div>
        <div className={`${classes.container} ${classes.catDraw}`}>
          <Drawer
            variant="persistent"
            anchor="left"
            open={openTypeDate}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeaderType}>
              <IconButton
                className={classes.icon}
                onClick={handleDrawerTypeDateClose}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                className={`${classes.icon} ${classes.refresh}`}
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </IconButton>
              <div>
                <div className={`${classes.filterTop} ${classes.container}`}>
                  Playing
                </div>
              </div>

              <div>
                <Calendar className={classes.cal} onChange={props.handleDate}/>
              </div>
            </div>
          </Drawer>
        </div>
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.rootImg}>
          <ImageList className={classes.imageList} cols={2.5}>
            {movieFilter}
          </ImageList>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </main>
    </div>
  );

  // <div>{movieFilter}</div>;
}

export default withStyles(styles, { withTheme: true })(Page);
