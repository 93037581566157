import React, { useState, useEffect } from "react";
import axios from "axios";
import Page from "./page";
import { useParams } from "react-router-dom";

function Root() {
  const [query, setQuery] = useState(null);
  const [movies, setMovies] = useState(null);
  const [place, setPlace] = useState("lat=51.5006&lon=-0.1465");
  const [radius, setRadius] = useState("19313");
  const [location, setLocation] = useState("gb");
  const [date, setDate] = useState(false);

  let dateData = new Date();

  let day = (dateData.getDate() + 1).toString().padStart(2, "0");
  let month = (dateData.getMonth() + 1).toString().padStart(2, "0");
  let year = dateData.getFullYear();

  let currentDate = `${year}${month}${day}`;
  function handleDate(e){
    dateData = e
    day = (dateData.getDate() + 1).toString().padStart(2, "0");;
    month = (dateData.getMonth() + 1).toString().padStart(2, "0");
    year = dateData.getFullYear();
  
    let newDate = `${year}${month}${day}`;
    async function getMovies() {
      setDate(newDate);
      const loop = 5;
      let m = [];
      for (let i = 1; i < loop; i++) {
        const response = await axios.get(
          `https://trailered.s-prod.pow.io/movies?dates=${newDate}&${place}&radius=${radius}&country=${location}&page=${i}&limit=20`
        );
        m = [...m, response.data.data.movies];
      }
      setMovies(m);
    }
    getMovies();
  }
  useEffect(() => {
    async function getMovies() {
      setDate(currentDate);
      const loop = 5;
      let m = [];
      for (let i = 1; i < loop; i++) {
        const response = await axios.get(
          `https://trailered.s-prod.pow.io/movies?dates=${currentDate}&${place}&radius=${radius}&country=${location}&page=${i}&limit=20`
        );
        m = [...m, response.data.data.movies];
      }
      setMovies(m);
    }
    getMovies();
  }, [query]);
  return <div>{movies && <Page movies={movies} handleDate={handleDate} date={date} currentDate={currentDate}/>}</div>;
}
export default Root;
